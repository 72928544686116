(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbAccount.wbCompany.factory:Card
   *
   * @description
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .factory('Card', Card);

  function Card(wbResource) {
    return wbResource('card', 'cards', {}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      }
    });
  }
}());
